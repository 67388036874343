.dept_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

.dept_list_item {
  font-family: 'Comfortaa', cursive;
  padding: 10px;
  border: 2px white solid;
  border-radius: 25px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
}

.active_dept_list_item {
  background-color: white;
  color: black;
}

@media only screen and (min-width: 500px) {

  .dept_list_item {
    padding: 10px;
  }

  .dept_list {
    justify-content: flex-start;
    flex-direction: row;
  }

}


@media only screen and (min-width: 900px) {

  .dept_list_item {
    padding: 15px;
    border-radius: 25px;
    margin: 25px;
  }

}