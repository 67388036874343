@import url(https://fonts.googleapis.com/css2?family=Audiowide&family=Comfortaa&display=swap);
.logo_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 50vh;
  width: 185px;
}

.staked_container, .sport_container {
  display: flex;
  flex-direction: row;
}

.staked_container {
  justify-content: center;
}

.sport_container {
  width: 90%;
  justify-content: space-around;
}

@media only screen and (min-width: 500px) {
  .logo_container {
    width: 380px
  }  
}

@media only screen and (min-width: 900px) {
  .logo_container {
    width: 500px
  }
}

.nav_container {
  display: flex;
  height: 10vh;
  justify-content: flex-end;
}
.nav_list {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  flex-direction: row;
}
.nav_list_item {
  display: none;
}

.burger_icon {
  font-size: 32px;
  padding: 10px;
  margin-right: 25px;
  color: white;
}

@media only screen and (min-width: 500px) {
  .nav_list_item {
    color: #FFF;
    font-size: 16px;
    padding: 25px;
    font-family: 'Audiowide', cursive;
    display: block;
  }
  .burger_icon {
    display: none;
  }
}



.privacy_container {
  padding: 5px;
}
.dept_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

.dept_list_item {
  font-family: 'Comfortaa', cursive;
  padding: 10px;
  border: 2px white solid;
  border-radius: 25px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
}

.active_dept_list_item {
  background-color: white;
  color: black;
}

@media only screen and (min-width: 500px) {

  .dept_list_item {
    padding: 10px;
  }

  .dept_list {
    justify-content: flex-start;
    flex-direction: row;
  }

}


@media only screen and (min-width: 900px) {

  .dept_list_item {
    padding: 15px;
    border-radius: 25px;
    margin: 25px;
  }

}
.mobile_nav_container {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black;
  height: 90vh;
  width: 95vw;
}
.mobile_nav_list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
}
.mobile_nav_list_item {
  color: #FFF;
  font-size: 14px;
  padding: 25px;
  font-family: 'Audiowide', cursive;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: #FFF 1px solid;
}

@media only screen and (min-width: 500px) {
  .mobile_nav_container {
    display: none
  }
}



* {
  box-sizing: border-box;
}

body {
  background-color: black;
  color: white;

}

h1 {
  font-family: 'Audiowide', cursive;
  font-size: 38px;
  margin: 0px;
}

h3 {
  font-size: 12px;
  margin: 0px;
  text-transform: uppercase;
  font-family: 'Comfortaa', cursive;
}

a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

.App {
  padding: 10px;
}


@media only screen and (min-width: 500px) {

  h1 {
    font-size: 75px;
  }
  h3 {
    font-size: 20px;
  }

}

@media only screen and (min-width: 900px) {

  h1 {
  font-size: 100px;
  }

  h3 {
    font-size: 25px;
  }


}



