.nav_container {
  display: flex;
  height: 10vh;
  justify-content: flex-end;
}
.nav_list {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  flex-direction: row;
}
.nav_list_item {
  display: none;
}

.burger_icon {
  font-size: 32px;
  padding: 10px;
  margin-right: 25px;
  color: white;
}

@media only screen and (min-width: 500px) {
  .nav_list_item {
    color: #FFF;
    font-size: 16px;
    padding: 25px;
    font-family: 'Audiowide', cursive;
    display: block;
  }
  .burger_icon {
    display: none;
  }
}


