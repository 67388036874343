@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Comfortaa&display=swap');

* {
  box-sizing: border-box;
}

body {
  background-color: black;
  color: white;

}

h1 {
  font-family: 'Audiowide', cursive;
  font-size: 38px;
  margin: 0px;
}

h3 {
  font-size: 12px;
  margin: 0px;
  text-transform: uppercase;
  font-family: 'Comfortaa', cursive;
}

a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

.App {
  padding: 10px;
}


@media only screen and (min-width: 500px) {

  h1 {
    font-size: 75px;
  }
  h3 {
    font-size: 20px;
  }

}

@media only screen and (min-width: 900px) {

  h1 {
  font-size: 100px;
  }

  h3 {
    font-size: 25px;
  }


}


