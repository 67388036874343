.logo_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 50vh;
  width: 185px;
}

.staked_container, .sport_container {
  display: flex;
  flex-direction: row;
}

.staked_container {
  justify-content: center;
}

.sport_container {
  width: 90%;
  justify-content: space-around;
}

@media only screen and (min-width: 500px) {
  .logo_container {
    width: 380px
  }  
}

@media only screen and (min-width: 900px) {
  .logo_container {
    width: 500px
  }
}
