.mobile_nav_container {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black;
  height: 90vh;
  width: 95vw;
}
.mobile_nav_list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
}
.mobile_nav_list_item {
  color: #FFF;
  font-size: 14px;
  padding: 25px;
  font-family: 'Audiowide', cursive;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: #FFF 1px solid;
}

@media only screen and (min-width: 500px) {
  .mobile_nav_container {
    display: none
  }
}


